import {cva} from 'class-variance-authority';

import {twMerge} from '@/stylesheets/twMerge';
import {useTranslations} from '@/hooks/useTranslations';
import Typography from '@/components/base/elements/Typography/Typography';
import Image from '@/components/base/elements/Image/Image';

import {categoryTagsFromTags} from '../utils/utils';

import StoryCardMeta from './StoryCardMeta';
import {type NewsCard} from './types';

const titleStyles = cva('group-hover:underline group-hover:text-link-hover', {
  variants: {
    isRegularTitle: {
      true: 'font-normal',
    },
  },
});

export default function StoryCard({
  handle,
  image,
  publishedAt,
  showImage = true,
  tags,
  title,
  isRegularTitle,
  className,
}: NewsCard) {
  const {localizePath} = useTranslations();

  return (
    <article className={twMerge('pb-lg border-b last:border-b-0', className)}>
      <a
        href={localizePath(`/news/${handle}`)}
        title={title}
        className="flex flex-row justify-between group"
      >
        <div>
          <StoryCardMeta date={publishedAt} tags={categoryTagsFromTags(tags)} />
          <Typography
            as="h2"
            size="t5"
            isRichtext={false}
            className={titleStyles({isRegularTitle})}
          >
            {title}
          </Typography>
        </div>
        {showImage && image && (
          <div className="w-1/2 pl-xl flex-shrink-0 hidden md:block">
            <Image
              src={image.src}
              srcSet={`${image.src}&width=816&height=352 2x`}
              alt={image.altText}
              height={176}
              loading="lazy"
              className="rounded-lg float-right"
            />
          </div>
        )}
      </a>
    </article>
  );
}
